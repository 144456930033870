var body = $("body");
var accordionToggle = $("#accordionDelivery .card .card-header").find(
  ".toggle"
);
var radioButtonCheck = accordionToggle.find(".radio-button > .radio-inner");
accordionToggle.on("click", function () {
  "use strict";
  var $radioButton = $(this).find(".radio-inner");
  radioButtonCheck.removeClass("checked");
  if (radioButtonCheck.hasClass("checked")) {
    radioButtonCheck.removeClass("checked");
  } else {
    $radioButton.addClass("checked");
  }
});

var accordionPaymentToggle = $("#accordionPayment .card .card-header").find(
  ".toggle"
);
var radioButtonCheckPayment = accordionPaymentToggle.find(
  ".radio-button > .radio-inner"
);
accordionPaymentToggle.on("click", function () {
  "use strict";
  var $radioButton = $(this).find(".radio-inner");
  radioButtonCheckPayment.removeClass("checked");
  if (radioButtonCheckPayment.hasClass("checked")) {
    radioButtonCheckPayment.removeClass("checked");
  } else {
    $radioButton.addClass("checked");
  }
});

var paymentToggle = $("#checkout").find(".payment-toggle");
var paymentContainer = $("#checkout").find("#order-details");
paymentToggle.on("click", function () {
  $(this).toggleClass("toggle");
  paymentContainer.toggleClass("expanded");
  body.toggleClass("overflow-hidden");
});

var Cart = function (container, type) {
  this._body = $("body");
  this._addButton = $(".add-to-cart-button");
  this._addQuantityInput = $(".add-to-cart-quantity");
  this._cartContainer = container;
  this._type = type;
  this.initialize();
};
Cart.prototype = {
  _body: null,
  _addButton: null,
  _addQuantityInput: null,
  _changeTimeout: null,
  _changeRequest: null,
  _cartContainer: null,
  _type: null,
  initialize: function () {
    var self = this;

    if (this._type == "full") {
      $.get("/cart/cart?type=full", {}, function (html) {
        self.openWithHTML(html);
      });
    }
    if (this._type == "side") {
      this._addButton.unbind("click").on("click", function (e) {
        var button = $(this);
        var stock = button.data("stock");
        var product = button.data("name");

        if ($(".product-show").length > 0) {
          var productData = button
            .parents(".product-show")
            .find(".product-data-layer");
        }

        if ($(e.currentTarget).parents(".product-inner").length > 0) {
          var productData = $(e.currentTarget)
            .parents(".product-inner")
            .find(".product-data-layer");
        }

        if (
          $(".add-to-cart-container").find("input.add-to-cart-quantity")
            .length > 0
        ) {
          var qty = $(".add-to-cart-container")
            .find("input.add-to-cart-quantity")
            .val();
        }

        // addToCartDataLayer(productData, qty);

        $("#toCartConfirmModal").find(".productID").text(product);

        if (stock <= 0) {
          $("#toCartModal").modal("show");
          $("#toCartModal .add-to-cart")
            .unbind("click")
            .on("click", function () {
              $("#toCartModal").modal("hide");
              self.addToCart(self, button, productData, qty);

              $("#toCartConfirmModal").modal("show");
              return false;
            });
        } else {
          $("#toCartConfirmModal").modal("show");
          self.addToCart(self, button, productData, qty);
        }

        $("html, body").animate({ scrollTop: 0 }, 500);
        return false;
      });
    }
  },
  openWithHTML: function (html) {
    var self = this;
    self._cartContainer.html(html).addClass("show");
    this.cartFunctions(self);
  },
  inject: function (html) {
    var self = this;
    self._cartContainer.html(html);
    this.cartFunctions(self);
  },
  cartFunctions: function (self) {
    self._cartContainer.find(".close-slide").on("click", function () {
      self._body.removeClass("overlay");
      self._cartContainer.removeClass("show");
    });

    self._cartContainer.find(".remove-button").on("click", function () {
      var product = $(this).parents(".product").find(".product-data-layer");

      self.change(
        "remove",
        {
          id: product.data("id"),
        },
        $(this)
      );

      return false;
    });

    self._cartContainer.find(".btn-plus").on("click", function () {
      var product = $(this).parents(".product");
      var productData = product.find(".product-data-layer");

      var qty = product.find(".qty");

      qty.val(parseInt(qty.val()) + 1).trigger("blur");
      addToCartDataLayer(productData, qty.val());

      return false;
    });

    self._cartContainer.find(".btn-minus").on("click", function (e) {
      e.stopPropagation();
      var product = $(this).parents(".product");
      var qty = product.find(".qty");

      qty.val(parseInt(qty.val() - 1)).trigger("blur");
      product.find(".product-data-layer").data("quantity", qty.val());
      removeFromCartDataLayer(product.find(".product-data-layer"));

      return false;
    });

    self._cartContainer.find(".qty").on("blur", function () {
      var product = $(this).parents(".product");

      self.change(
        "qty",
        {
          id: product.data("id"),
          qty: product.find(".qty").val(),
        },
        $(this)
      );

      return false;
    });

    self._cartContainer.find("#side-cart-close").on("click", function () {
      $(self._cartContainer).removeClass("show");
    });
  },
  change: function (action, data, element) {
    var self = this;

    if (self._changeRequest != null) {
      self._changeRequest.abort();
    }
    if (self._changeTimeout != null) {
      clearTimeout(self._changeTimeout);
    }

    self._changeTimeout = setTimeout(function () {
      element
        .parents(".product")
        .find(".quantity")
        .prepend('<div class="list-loader"></div>');
      self._cartContainer.find(".cart-overlay").show();

      self._changeRequest = $.post(
        "/cart/" + action + "?type=" + self._type,
        data,
        function (html) {
          self.openWithHTML(html);
          getStatus();
        }
      );

      if (action === "remove") {
        var product = element.parents(".product").find(".product-data-layer");
        var qty = product.find(".qty");
        product.data("quantity", qty.val() - 1);
        removeFromCartDataLayer(product);
      }
    }, 600);
  },
  addToCart: function (self, button, productData, qty) {
    $.post(
      "/cart/add",
      {
        id: button.data("id"),
        qty:
          self._addQuantityInput.length == 0 ? 1 : self._addQuantityInput.val(),
        mobile: button.hasClass("add-to-cart-mobile"),
      },
      function (html) {
        if (button.hasClass("add-to-cart-mobile")) {
          $("#addToCartPopup").html(html).modal();
          getStatus();
        } else {
          self.openWithHTML(html);
          getStatus();
        }
        $("#toCartConfirmModal").modal("show");
        addToCartDataLayer(productData, qty);
      }
    );
  },
};

var fullCartPlaceholder = $(".full-cart-placeholder");
var sideCartPlaceholder = $(".side-cart-placeholder");
if (fullCartPlaceholder.length > 0) {
  fullCartPlaceholder.prepend('<div class="list-loader"></div>');
  new Cart(fullCartPlaceholder, "full");
} else {
  new Cart(sideCartPlaceholder, "side");
}

getStatus();

function getStatus() {
  $.get("/cart/status", function (response) {
    var data = JSON.parse(response);

    $(".my-account .cart .count")
      .show()
      .html(data.cart > 0 ? data.cart : "");

    if (data.user != null) {
      $(".account-text").html(
        "<span class='d-none d-md-inline'>Moj račun</span>"
      );
    } else {
      $(".account-text").html(
        "<span class='d-none d-md-inline'>Prijava</span>"
      );
    }

    $.get("/cart/cart?type=side", function (html) {
      new Cart(sideCartPlaceholder, "side").inject(html);
    });
  });
}

var Checkout = function (container, type) {
  this.initialize();
};
Checkout.prototype = {
  initialize: function () {
    var self = this;
    if ($("#account-checkbox").is(":checked")) {
      $(".form-password").show().find("input").prop("required", true);
    }

    $("#account-checkbox").on("click", function () {
      if ($(this).is(":checked")) {
        $(".form-password").show().find("input").prop("required", true);
      } else {
        $(".form-password").hide().find("input").prop("required", false);
      }
    });

    $(".delivery-address").find("input").prop("required", false);
    $("#delivery-checkbox").on("click", function () {
      if ($(this).is(":checked")) {
        $(".delivery-address").show().find("input").prop("required", true);
      } else {
        $(".delivery-address").hide().find("input").prop("required", false);
      }
      self.changeDeliveryCountry();
    });

    $(".address-card").on("click", function () {
      $(".address-card.active.selected").removeClass(["active", "selected"]);

      $(this).addClass(["active", "selected"]);

      var address = $(this).data("address");

      $(".delivery-address input[name=delivery_address_id]").val(address.id);

      Object.keys(address).forEach(function (key) {
        $(".delivery-address input[name=delivery_" + key + "]").val(
          address[key]
        );
        $(".delivery-address select[name=delivery_" + key + "]").val(
          address[key]
        );
      });
    });

    self.saveDeliveryChoice(
      $("input[name=payment_type_id]:checked").val(),
      $("input[name=delivery_type_id]:checked").val(),
      $("select[name=billing_country_id]").val()
    );

    $(
      "#deliveryTypeAccordion .form-check.delivery_" +
        $("select[name=billing_country_id]").val()
    ).show();
    $(
      "#deliveryTypeAccordion .form-check.delivery_" +
        $("select[name=billing_country_id]").val() +
        " input"
    ).prop("checked", true);

    $("#billing_country_id").on("change", function () {
      if ($("#delivery-checkbox").is(":checked")) {
        return;
      }
      self.changeDeliveryCountry();
    });

    $("#delivery_country_id").on("change", function () {
      if (!$("#delivery-checkbox").is(":checked")) {
        return;
      }
      self.changeDeliveryCountry();
    });

    $("#delivery input").on("change", function () {
      self.saveDeliveryChoice(
        $("input[name=payment_type_id]:checked").val(),
        $("input[name=delivery_type_id]:checked").val(),
        $("select[name=billing_country_id]").val()
      );
    });

    $("select#billing_country_id").on("change", function () {
      self.saveDeliveryChoice(
        $("input[name=payment_type_id]:checked").val(),
        $("input[name=delivery_type_id]:checked").val(),
        $("select[name=billing_country_id]").val()
      );
    });

    $("select#delivery_country_id").on("change", function () {
      self.saveDeliveryChoice(
        $("input[name=payment_type_id]:checked").val(),
        $("input[name=delivery_type_id]:checked").val(),
        $("select[name=delivery_country_id]").val()
      );
    });
  },
  changeDeliveryCountry: function () {
    var country = $("#billing_country_id");
    if ($("#delivery-checkbox").is(":checked")) {
      country = $("#delivery_country_id");
    }
    var countryId = country.val();
    $("#deliveryTypeAccordion .form-check.delivery_" + countryId).show();
    $("#deliveryTypeAccordion .form-check.delivery_" + countryId + " input")
      .prop("checked", true)
      .change();
  },
  saveDeliveryChoice: function (payment, delivery, country) {
    $(".checkout-loader").show();
    var self = this;
    $.post(
      "/cart/checkout-delivery",
      {
        payment_type_id: payment != null ? payment : null,
        delivery_type_id: delivery != null ? delivery : null,
        country_id: country != null ? country : null,
      },
      function (html) {
        self.applySummary(html);
      }
    );
  },
  applySummary: function (html) {
    if ($("input.payment-instalments").is(":checked")) {
      this.reloadInstalments();
    }

    var self = this;
    $(".checkout-summary-placeholder").html(html);

    $(".coupon-form").on("submit", function () {
      var code = $("#discount-code").val();

      $.post("/cart/checkout-coupon", { code: code }, function (html) {
        self.applySummary(html);
      });

      return false;
    });

    $(".coupon-remove").on("click", function () {
      $.get("/cart/checkout-coupon-remove", function (html) {
        self.applySummary(html);
      });
      return false;
    });
    $(this).closest(".list-loader").hide();
  },
  reloadInstalments: function () {
    var paymentInstalmentsContainer = $(".payment-instalments-container");
    $.get("/cart/payment-instalments", function (response) {
      $(this).closest(".list-loader").hide();
      paymentInstalmentsContainer.html(response);
      paymentInstalments();
      instalments();
    });
    return false;
  },
};

if ($("#checkout").length > 0) {
  new Checkout();
}

$(".cart-inner .close").on("click", function () {
  $("#cart-container").removeClass("show");
});

$(".wishlist-inner .close").on("click", function () {
  $("#cart-container").removeClass("show");
});

var companyCheckbox = $(".personal").find("#company-checkbox");
var companyCont = $(".personal").find("#company");
companyCheckbox.on("click", function () {
  $(companyCont).toggleClass("show");
});

setTimeout(function () {
  if (companyCheckbox.is(":checked")) {
    $(companyCont).addClass("show");
  }
}, 500);

var selectedPickup = $(".form-check.pickup .form-check-input");
var selectedDelivery = $(".form-check.configurator .form-check-input");
var differentAddress = $(".mt-5 .form-group-switch");
var deliveryAddress = $(".delivery-address");

selectedPickup.on("change", function () {
  if ($(this).is(":checked")) {
    console.log("pickup is checked");
    differentAddress.hide();
    deliveryAddress.find("input").prop("required", false);

    deliveryAddress.hide();
  }
});
selectedDelivery.on("change", function () {
  if ($(this).is(":checked")) {
    console.log("delivery is checked");
    differentAddress.show();

    if ($("#delivery-checkbox").is(":checked")) {
      deliveryAddress.show();
      deliveryAddress.find("input").prop("required", true);
    } else {
      deliveryAddress.hide();
    }
  }
});
