var DataLayer = {
  viewItemList: function (viewData) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'view_item_list',
      'ecommerce': viewData
    });
  },
  selectItem: function (viewData) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'select_item',
      'ecommerce': viewData
    });
  },
  viewItem: function (viewData) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'view_item',
      'ecommerce': viewData
    });
  },
  addToCart: function (productData) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'add_to_cart',
      'ecommerce': productData
    });
  },
  addToWishlist: function (productData) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'add_to_wishlist',
      'ecommerce': productData
    });
  },
  viewCart: function (productData) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'view_cart',
      'ecommerce': productData
    });
  },
  removeFromCart: function (productData) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'remove_from_cart',
      'ecommerce': productData
    });
  },
  beginCheckout: function (productData) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'begin_checkout',
      'ecommerce': productData
    });
  },
  addShippingInfo: function (productData) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'add_shipping_info',
      'ecommerce': productData
    });
  },
  addPaymentInfo: function (productData) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'add_payment_info',
      'ecommerce': productData
    });
  },
  purchase: function (purchaseData) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'purchase',
      'ecommerce': purchaseData
    });
  },
  viewPromotion: function (promotionData) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'view_promotion',
      'ecommerce': purchaseData
    });
  },
  selectPromotion: function (promotionData) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'select_promotion',
      'ecommerce': purchaseData
    });
  },
};

/*** View item list, select item, view item ***/
function viewItemList() {
  setTimeout(function () {
    var products = getProducts($("#productList"));
    var list = $('#productList').find('#viewItemListDataLayer');
    var viewData = [];
    var data = {
      item_list_id: list.data("list-id"),
      item_list_name: list.data("list-name"),
      items: products
    };
    
    var selectItemLink = $('.product-inner').find("a.select-item");
    viewData.push(data);
    
    if ($('#productList').length > 0) {
      DataLayer.viewItemList(viewData);
    }
  }, 3000);
}

if ($(".product-show").length > 0) {
  var product = getProducts();
  var list = $('#productList').find('#viewItemListDataLayer');
  
  var viewData = [];
  var data = {
    item_list_id: list.data("list-id"),
    item_list_name: list.data("list-name"),
    items: product,
  };
  viewData.push(data);
  DataLayer.selectItem(viewData);
  
  var viewData = [];
  var data = {
    currency: "EUR",
    value: parseFloat(product[0].price),
    items: product
  };
  
  viewData.push(data);
  
  DataLayer.viewItem(viewData);
}

function addToCartDataLayer(product, qty) {
  var productData = [];
  var data = {
    currency: "EUR",
    value: null,
    items: [],
  };
  
  data.value = parseFloat(product.data("price"));
  
  var item = {
    item_id: null,
    item_name: null,
    discount: null,
    item_category: null,
    item_variant: null,
    price: null,
    quantity: qty ? qty : 1,
  };
  
  item.item_id = product.data("product-code");
  item.item_name = product.data("name");
  item.discount = product.data("discount") ? product.data("discount") : 0;
  item.item_category = product.data("category");
  
  var categories = $(product).data("item-subcategories").split(";");
  $(categories).each(function (i, category) {
    if (category !== '') {
      item["item_category" + i] = category;
    }
  });
  
  item.item_variant = product.data("variant") ? product.data("variant") : '';
  item.price = parseFloat(product.data("price"));
  
  data.items = item;
  
  productData.push(data);
  
  DataLayer.addToCart(productData);
}

function addToWishlistDataLayer(product) {
  var productData = [];
  var data = {
    currency: "EUR",
    value: null,
    items: [],
  };
  
  data.value = parseFloat(product.data("price"));
  
  var item = {
    item_id: null,
    item_name: null,
    discount: null,
    item_category: null,
    item_variant: null,
    price: null,
    quantity: 1,
  };
  
  item.item_id = product.data("product-code");
  item.item_name = product.data("name");
  item.discount = product.data("discount") ? product.data("discount") : 0;
  item.item_category = product.data("category");
  
  var categories = product.data("item-subcategories").split(";");
  $(categories).each(function (i, category) {
    if (category !== '') {
      item["item_category" + i] = category;
    }
  });
  
  item.item_variant = product.data("variant") ? product.data("variant") : '';
  item.price = parseFloat(product.data("price"));
  
  data.items = item;
  
  productData.push(data);
  
  DataLayer.addToWishlist(productData);
}

/*** View cart ***/
setTimeout(function () {
  if ($(".cartfull-products-list").length > 0) {
    var cartTotal = $("#cartTotal").data("total");
    var products = getProducts($(".cartfull-products-list"));
    
    /*** BEGIN CHECKOUT ***/
    var viewCart = [];
    var data = {
      currency: "EUR",
      value: parseFloat(cartTotal),
      items: products
    };
    
    viewCart.push(data);
    
    DataLayer.viewCart(viewCart);
  }
}, 3000);

function removeFromCartDataLayer(product) {
  var item = {
    item_id: null,
    item_name: null,
    discount: null,
    item_category: null,
    item_variant: null,
    price: null,
    quantity: null
  }
  item.item_id = product.data("product-code");
  item.item_name = product.data("name");
  item.discount = product.data("discount");
  item.item_category = product.data("item-category");
  
  var categories = product.data("item-subcategories").split(";");
  $(categories).each(function (i, category) {
    if (category !== '') {
      item["item_category" + i] = category;
    }
  });
  
  item.item_variant = product.data("variant");
  item.price = parseFloat(product.data("price"));
  item.quantity = product.data("quantity");
  
  var removeData = [];
  var data = {
    currency: "EUR",
    value: parseFloat(product.data("price")),
    items: item
  };
  
  removeData.push(data);
  
  DataLayer.removeFromCart(removeData);
}

if ($('#checkoutForm').length > 0) {
  var layerCheckout = $("#layerCheckout");
  var products = getProducts();
  
  /*** BEGIN CHECKOUT ***/
  var checkoutData = [];
  var data = {
    currency: "EUR",
    value: parseFloat(layerCheckout.data("value")),
    coupon: layerCheckout.data("coupon"),
    items: products
  };
  
  checkoutData.push(data);
  DataLayer.beginCheckout(checkoutData);
  
  $('#checkoutForm').submit(function () {
    /*** ADD DELIVERY ***/
    var deliveryTypeRadio = $("#deliveryTypeAccordion").find($('input[name="delivery_type_id"]:checked'));
    var deliveryType = deliveryTypeRadio.closest(".form-check-label").find(".radio_label").html();
    var shippingData = [];
    var data = {
      currency: "EUR",
      value: parseFloat(layerCheckout.data("value")),
      coupon: layerCheckout.data("coupon"),
      shipping_tier: deliveryType,
      items: products,
    };
    
    shippingData.push(data);
    
    /*** ADD PAYMENT ***/
    var paymentTypeRadio = $("#paymentTypeAccordion").find($('input[name="payment_type_id"]:checked'));
    var paymentType = paymentTypeRadio.closest(".form-check-label").find(".radio_label").html();
    var paymentData = [];
    var data = {
      currency: "EUR",
      value: parseFloat(layerCheckout.data("value")),
      coupon: layerCheckout.data("coupon"),
      payment_type: paymentType,
      items: products,
    };
    
    paymentData.push(data);
    
    /*** PUSH TO DATALAYER ***/
    DataLayer.addPaymentInfo(paymentData);
    DataLayer.addShippingInfo(shippingData);
  });
}

if ($("#order-finalize").length > 0) {
  /*** PURCHASE ***/
  var layerCheckout = $(".data-layer > input");
  var products = getProducts();
  
  var purchaseData = [];
  var data = {
    transaction_id: String(layerCheckout.data("transaction-id")),
    affiliation: layerCheckout.data("affiliation"),
    value: parseFloat(layerCheckout.data("value")),
    tax: parseFloat(layerCheckout.data("tax")),
    currency: "EUR",
    coupon: layerCheckout.data("coupon"),
    items: products,
  };
  
  purchaseData.push(data);
  DataLayer.purchase(purchaseData);
}


function getProducts(container) {
  var productsData = [];
  var products = $(".product-data-layer");
  if (container) {
    var products = $(container).find(".product-data-layer");
  }
  
  products.each(function () {
    var item = {
      item_id: null,
      item_name: null,
      discount: null,
      item_category: null,
      item_variant: null,
      price: null,
      quantity: null
    }
    item.item_id = $(this).data("product-code");
    item.item_name = $(this).data("name");
    item.discount = $(this).data("discount");
    item.item_category = $(this).data("item-category");
    
    var categories = $(this).data("item-subcategories").split(";");
    $(categories).each(function (i, category) {
      if (category !== '') {
        item["item_category" + i] = category;
      }
    });
    
    item.item_variant = $(this).data("variant");
    item.price = parseFloat($(this).data("price"));
    item.quantity = $(this).data("quantity");
    
    productsData.push(item);
  });
  
  return productsData;
}
