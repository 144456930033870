var Wishlist = function (container, type) {
  this._body = $("body");
  this._addButton = $(".add-to-wishlist-button");
  this._addQuantityInput = $(".add-to-wishlist-quantity");
  this._wishlistContainer = container;
  this._type = type;
  this.initialize();
};

Wishlist.prototype = {
  _body: null,
  _addButton: null,
  _addQuantityInput: null,
  _changeTimeout: null,
  _changeRequest: null,
  _wishlistContainer: null,
  _type: null,
  initialize: function () {
    var self = this;
    
    if (this._type == 'full') {
      $.get('/wishlist/wishlist?type=full', {}, function (html) {
        self.openWithHTML(html);
      });
    }
    if (this._type == 'side') {
      this._addButton.unbind('click').on('click', function () {
        var button = $(this);
        
        var productData = button.parents('.product-show').find(".product-data-layer");
        addToWishlistDataLayer(productData);
        
        $.post('/wishlist/add', {
          product_id: $(this).data('id'),
          quantity: self._addQuantityInput.length == 0 ? 1 : self._addQuantityInput.val(),
          list_type: "wishlist"
        }, function (html) {
          self.openWithHTML(html);
        });
        
        return false;
      });
    }
  },
  openWithHTML: function (html) {
    var self = this;
    
    if (this._type == 'side') {
      self._body.addClass('overlay');
    }
    self._wishlistContainer.html(html).addClass('show');
    
    self._wishlistContainer.find('.close-slide').on('click', function () {
      self._body.removeClass('overlay');
      self._wishlistContainer.removeClass('show');
    });
    
    self._wishlistContainer.find('.remove-item').on('click', function () {
      var product = $(this).parents('.product');
      
      self.change('remove', {
        product_id: product.data('id')
      }, $(this));
      
      return false;
    });
    
    self._wishlistContainer.find('#side-wishlist-close').on('click', function () {
      $(self._wishlistContainer).removeClass('show');
    });
  },
  change: function (action, data, element) {
    var self = this;
    
    if (self._changeRequest != null) {
      self._changeRequest.abort();
    }
    if (self._changeTimeout != null) {
      clearTimeout(self._changeTimeout);
    }
    
    self._changeTimeout = setTimeout(function () {
      element.parents('.product').find(".quantity").prepend('<div class="list-loader"></div>');
      self._wishlistContainer.find('.wishlist-overlay').show();
      
      self._changeRequest = $.post('/wishlist/' + action + '?type=' + self._type, data, function (html) {
        self.openWithHTML(html);
        getWishlistStatus();
      });
    }, 600);
  }
};

var fullWishlistPlaceholder = $(".full-wishlist-placeholder");
var sideWishlistPlaceholder = $(".side-wishlist-placeholder");
if (fullWishlistPlaceholder.length > 0) {
  fullWishlistPlaceholder.prepend('<div class="list-loader"></div>');
  new Cart(fullWishlistPlaceholder, 'side');
  new Wishlist(fullWishlistPlaceholder, 'full');
} else {
  new Wishlist(sideWishlistPlaceholder, 'side');
}

getWishlistStatus();

function getWishlistStatus() {
  $.get('/wishlist/status', function (response) {
    var data = JSON.parse(response);
    
    $(".my-account .wishlist .count").show().html(data.wishlist > 0 ? data.wishlist : '');
    
    $.get('/wishlist/wishlist?type=side', function (html) {
      new Wishlist(sideWishlistPlaceholder, 'side').openWithHTML(html);
      sideWishlistPlaceholder.removeClass("show");
    });
  });
}
