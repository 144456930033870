function instalments() {
  $('#nlbObroki').change(function () {
    var selected = this.options[this.selectedIndex];
    
    if (this.value != 0) {
      document.getElementById('calculationNlb').style.display = 'block';
      document.getElementById('monthlyPaymentNlb').innerHTML = this.value;
      document.getElementById('finalPriceNlb').innerHTML = selected.getAttribute('data-final');
      
      document.getElementById("instalmentName").value = 'NLB';
      document.getElementById("noInstalments").value = selected.getAttribute('data-instalments');
      document.getElementById("monthlyPayment").value = this.value;
      document.getElementById("instalmentsTotal").value = selected.getAttribute('data-final');
    } else {
      document.getElementById('calculationNlb').style.display = 'none';
      document.getElementById('monthlyPaymentNlb').innerHTML = "";
      document.getElementById('finalPriceNlb').innerHTML = "";
    }
  });
  
  $('#dinersObroki').change(function () {
    var selected = this.options[this.selectedIndex];
    
    if (this.value != 0) {
      document.getElementById('calculationDiners').style.display = 'block';
      document.getElementById('monthlyPaymentDiners').innerHTML = this.value;
      document.getElementById('finalPriceDiners').innerHTML = selected.getAttribute('data-final');
      
      document.getElementById("instalmentName").value = 'NLB';
      document.getElementById("noInstalments").value = selected.getAttribute('data-instalments');
      document.getElementById("monthlyPayment").value = this.value;
      document.getElementById("instalmentsTotal").value = selected.getAttribute('data-final');
    } else {
      document.getElementById('calculationDiners').style.display = 'none';
      document.getElementById('monthlyPaymentDiners').innerHTML = "";
      document.getElementById('finalPriceDiners').innerHTML = "";
    }
  });
}

instalments();

var inputInstalments = $("input.payment-instalments");
if (inputInstalments.length > 0) {
  $(inputInstalments).on("click", function () {
    var paymentInstalmentsContainer = $(".payment-instalments-container");
    $.get('/cart/payment-instalments', function (response) {
      paymentInstalmentsContainer.html(response);
      paymentInstalments();
      instalments();
    });
  });
} else {
  paymentInstalments();
}

function paymentInstalments() {
  var paymentTypeAccordion = $("#paymentTypeAccordion");
  var deliveryTypeAccordion = $("#deliveryTypeAccordion");
  var paymentInstCheck = paymentTypeAccordion.find("input.payment-instalments");
  var deliveryTypeConfCheck = deliveryTypeAccordion.find(".form-check.configurator");
  
  paymentTypeAccordion.on("change", function () {
    deliveryTypeConfCheck.show();
    if (paymentInstCheck.is(':checked')) {
      deliveryTypeConfCheck.hide();
    }
  })
  
  var monthlyPaymentCalcNlbButton = paymentTypeAccordion.find(".payment-property.nlb");
  var monthlyPaymentCalcDinersButton = paymentTypeAccordion.find(".payment-property.diners");
  var paymentDescNlb = $(".payments-description.nlb");
  var paymentDescDiners = $(".payments-description.diners");
  
  monthlyPaymentCalcNlbButton.on("click", function (e) {
    e.preventDefault();
    paymentDescDiners.hide();
    paymentDescNlb.toggle();
  });
  
  monthlyPaymentCalcDinersButton.on("click", function (e) {
    e.preventDefault();
    paymentDescNlb.hide();
    paymentDescDiners.toggle();
  });
}